<template>
  <div v-cloak class='t-list' v-if="isList">
    <div :style="flex?'display:flex;justify-content: space-between;':''">
      <div v-if='(buttonPermissions.some(obj => operationButtons.includes(obj.type))) || hasBack' style='margin-bottom: 20rem;flex: 1; display: inline-flex;'>
        <el-button v-if='hasBack' icon='el-icon-arrow-left' size='small' type='primary' @click="$emit('onBack')
             $router.back()">返回
        </el-button>
        <el-button v-for="item in buttonPermissions" v-if="operationButtons.includes(item.type) && !isHide[item.type]" :disabled="isDisabled[item.type]" size='small' :type="getButtonType(item.type)" @click="$emit('on'+item.type.charAt(0).toUpperCase() + item.type.slice(1))">
          {{ item.button }}
        </el-button>

        <!--        <el-button v-if="buttons.some(obj => obj.type === 'add')" :icon="addText === '新增' ? 'el-icon-plus' : ''" size='small' type='primary' @click="$emit('onAdd')">{{ addText }}</el-button>-->
        <!--        <el-button v-if="uploadFile" size='mini' style='height:32rem' type='primary' @click="$emit('uploadDialogShow')">批量导入</el-button>-->
      </div>
      <slot name='title-left'></slot>
      <t-search-block v-if='searchConfig'
                      ref='searchBlock'
                      :download-text="downloadText"
                      :downloadDisabled="downloadDisabled"
                      :has-add="hasAdd"
                      :is-check-input-box="isCheckInputBox"
                      :is-download="isDownload"
                      :reset-no-request="resetNoRequest"
                      :search-config='searchConfig'
                      :searchLoading="loading"
                      @onFlex="(e)=>flex=e"
                      @onChange="val=>$emit('onChange',val)"
                      @onDownload="$emit('onDownload')"
                      @onReset="$emit('onReset')"
                      @onSearch="res=>$emit('onSearch',JSON.parse(JSON.stringify(res)))">
      </t-search-block>
      <slot name='title'></slot>
    </div>
    <!--		<div v-if="(tableData && tableData.length > 0) || loading">-->
    <div>
      <div :class="tableStyle === 0 ? 't-table' : ''">
        <el-table
            ref="table"
            v-loading='loading'
            :cell-style='cellStyle'
            :data='tableData'
            :stripe='false'
            size='small'
            style='width: 100%'
            v-bind='property'
            v-on='events'
            @sort-change="sortChange"
        >
          <template slot='empty'>
            <t-result :text="empty_text" type='empty'></t-result>
          </template>
          <template
              v-for='(item, index) in tableConfig'>
            <el-table-column
                :key="`${item.prop || 'handle'}-table-column-${index}`"
                v-if="!['index','expand','selection','number'].includes(item.type)"
                :fixed="item.fixed"
                :label='item.label'
                :min-width='item.minWidth'
                :prop='item.prop'
                :show-overflow-tooltip='item.showOverflowTooltip==null?true:item.showOverflowTooltip'
                :sortable="item.sortable || false"
                :type='item.type'
                :width='computedColWidth(item.width)'
                v-bind='item.property||{}'>
              <template slot-scope='scope'>
                <div v-if='!!item.handle'>
                  <!--                  <template v-for='(handle, index) in item.render(scope.row)'>-->
                  <!--                    <span v-if="['无', '-', '&#45;&#45;'].includes(handle)">{{ handle }}</span>-->
                  <!--                    <a v-else class='t-handle' href='javascript:' @click='onHandle(scope.row, handle, index)'>{{-->
                  <!--                        handle-->
                  <!--                      }}</a>-->
                  <!--                  </template>-->
                  <template v-for='(handle, index) in scope.row.button_options' v-if="!!buttonPermissions.length && !!scope.row.button_options">
                    <a v-if="handle.show && hasPermissions(handle.type)"
                       class='t-handle' href='javascript:' @click='onHandle(scope.row, handle, index)'>
                      {{ handle.button }}
                    </a>
                  </template>
                  <template v-for='(handle, index) in buttonPermissions' v-if="!!buttonPermissions.length && !scope.row.button_options">
                    <a v-if="!operationButtons.includes(handle.type)"
                       class='t-handle' href='javascript:' @click='onHandle(scope.row, handle, index)'>
                      {{ handle.button }}
                    </a>
                  </template>
                  <template v-if="!buttonPermissions.length">
                    <a>--</a>
                  </template>
                </div>
                <div v-else-if="item.type === 'switch'">
                  <el-switch
                      v-model="scope.row[item.prop]"
                      active-color="#1d2088"
                      inactive-color="rgba(0,0,0,.2)"
                      @change="onSwitch(scope.row)">
                  </el-switch>
                </div>
                <div v-else-if="item.type === 'switch2'">
                  <el-switch
                      v-model="scope.row[item.prop]"
                      :active-value=1
                      :inactive-value=0
                      active-color="#13ce66"
                      @change="onSwitch(scope.row)"
                  >
                  </el-switch>
                </div>
                <div v-else :class="item.showOverflowTooltip || item.showOverflowTooltip == null?'text-one':''">
                  <!--       修改逻辑 : 之前的逻辑会将0一起给屏蔽掉 , 有的数据就是0, 需要展示(只屏蔽为null或者空字符串的无用数据)         -->
                  <!-- <span v-if='!item.render'>{{ scope.row[item.prop] == null || scope.row[item.prop] === "" ? "-" : scope.row[item.prop] }} </span>
                  <span v-else v-html='item.render(scope.row)'></span> -->

                  <!-- 兼容列表点击事件 -->
                  <template v-if="item.render">
                    <template v-if='isShowRenderButton(item.render, scope.row)'>
                      <template v-for='(handle, index) in item.render(scope.row)'>
                        <span v-if="handle.text !== 0 && !handle.text">-</span>
                        <span v-else-if="['无', '-', '--'].includes(handle.text)">{{ handle.text }}</span>
                        <a v-else class='t-handle' @click='onHandle(scope.row, handle, index)'>{{ handle.text }}</a>
                      </template>
                    </template>
                    <span v-else v-html='showRenderValue(item.render(scope.row))'></span>
                  </template>
                  <span v-else>{{ showRenderValue(scope.row[item.prop]) }}</span>
                </div>
              </template>

              <!-- 合并单元格 -->
              <template
                  v-for='_item in item.children'>
                <el-table-column
                    v-if="!['index','expand','selection','number'].includes(_item.type)"
                    :fixed="_item.fixed"
                    :label='_item.label'
                    :min-width='_item.minWidth'
                    :prop='_item.prop'
                    :show-overflow-tooltip='_item.showOverflowTooltip==null?true:_item.showOverflowTooltip'
                    :sortable="item.sortable || false"
                    :type='_item.type'
                    :width='computedColWidth(_item.width)'
                    v-bind='_item.property||{}'>
                  <template slot-scope='scope'>
                    <div v-if='!!_item.handle'>{{ _item.handle }}
                      <template v-for='(handle, index) in _item.render(scope.row)'>
                        <span v-if="['无', '-', '--'].includes(handle)">{{ handle }}</span>
                        <a v-else class='t-handle' href='javascript:' @click='onHandle(scope.row, handle, index)'>{{
                            handle
                          }}</a>
                      </template>
                    </div>
                    <div v-else-if="_item.type === 'switch'">
                      <el-switch
                          v-model="scope.row[_item.prop]"
                          active-color="#1d2088"
                          inactive-color="rgba(0,0,0,.2)"
                          @change="onSwitch(scope.row)">
                      </el-switch>
                    </div>
                    <div v-else :class="_item.showOverflowTooltip || _item.showOverflowTooltip == null?'text-one':''">
                      <!-- <span v-if='!_item.render'>{{ scope.row[_item.prop] }} </span>
                      <span v-else v-html='_item.render(scope.row)'></span> -->
                      <!-- 兼容列表点击事件 -->
                      <template v-if="_item.render">
                        <template v-if='isShowRenderButton(_item.render, scope.row)'>
                          <template v-for='(handle, index) in _item.render(scope.row)'>
                            <span v-if="handle.text !== 0 && !handle.text">-</span>
                            <span v-else-if="['无', '-', '--'].includes(handle.text)">{{ handle.text }}</span>
                            <a v-else class='t-handle' @click='onHandle(scope.row, handle, index)'>{{ handle.text }}</a>
                          </template>
                        </template>
                        <span v-else v-html='showRenderValue(_item.render(scope.row))'></span>
                      </template>
                      <span v-else>{{ showRenderValue(scope.row[_item.prop]) }}</span>
                    </div>
                  </template>
                </el-table-column>
              </template>

            </el-table-column>
            <el-table-column v-else-if="item.type === 'number'" :key="item.type + index" :fixed="item.fixed" :label='item.label'
                             :width='computedColWidth(item.width)' v-bind='item.property||{}'>
              <template slot-scope='scope'>
                {{ (currentPage - 1) * pageSize + scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column v-else :key="index + item.type"
                             :fixed="item.fixed"
                             :label='item.label'
                             :min-width='item.minWidth'
                             :prop='item.prop'
                             :show-overflow-tooltip='item.showOverflowTooltip==null?true:item.showOverflowTooltip'
                             :sortable="item.sortable || false"
                             :type='item.type'
                             :width='computedColWidth(item.width || 50)'
                             v-bind='item.property||{}'>
            </el-table-column>
          </template>
          <slot name='column'></slot>
        </el-table>
      </div>
      <div class='t-pagination'>
        <el-pagination
            v-if='total != null'
            :current-page='currentPage'
            :page-size='pageSize'
            :total='total'
            background
            layout='total, prev, pager, next,jumper'
            @current-change='changeCurrentPage'
        >
        </el-pagination>
      </div>
    </div>
    <!--		<t-result v-else type="empty"></t-result>-->
  </div>
  <div v-else class="t-list">
    <table-template-top v-bind="tableTemplateProperty(1).props" v-on="tableTemplateProperty(1).events" />
    <table-template
        v-bind="tableTemplateProperty().props"
        v-on="tableTemplateProperty().events"
        />
  </div>
</template>

<script>
import Button from '../Button'
import TSearchBlock from "../SearchBlock";
import TableTemplate from "@/components/table/TableTemplate.vue";
import TableTemplateTop from "@/components/table/TableTemplateTop.vue";
import {tableProps,topProps,tableTopEvents,tableEvents} from "@/components/table";
import {mapState} from "vuex";
/*
function debounce(func, wait=1000){ //可以放入项目中的公共方法中进行调用
  let timeout;
  return function(event){
  clearTimeout(timeout)
  timeout = setTimeout(()=>{
  func.call(this, event)
  },wait)
  }
}
 */
export default {
  name: 'list-template',
  provide() {
    return {
      table: this
    }
  },
  props: {
    ...topProps,
    ...tableProps,
    // 是否有返回按钮
    hasBack: Boolean,
    // 是否有新增按钮
    hasAdd: Boolean,
    // 搜索的配置
    // tag : 标签类型: input , select
    // type : input标签的type(input标签独有)
    // prop : 绑定的数据的参数名  , 在点击搜索按键时回调里会用到
    // placeholder : 原生的占位符
    // options: select的option 配置  数组类型 , 其中对应有value,和label  (不一定非得这两个值) 在下面配置
    // label : select label的映射键  默认 'label'
    // value : select value的映射键  默认 'value'
    searchConfig: Array,
    // 表格事件
    // 参考 https://element.eleme.cn/#/zh-CN/component/table#table-events
    events: Object,
    // 表格属性
    // 参考 https://element.eleme.cn/#/zh-CN/component/table#table-attributes
    property: Object,
    // 表格配置
    // prop 字段名
    // label 列展示名
    // width 列宽
    // type 列的类型   可选值: selection/index/expand
    // handle 是否为操作列
    // render {return : Array[string]} 渲染操作 , 返回数组 没有不写即可
    tableConfig: Array,
    // 表格数据
    tableData: Array,
    // 分页配置:
    // total :  总数据数量
    total: Number,
    // currentPage :  当前页码
    currentPage: Number,
    // 加载中
    loading: {
      type: [Number, Boolean],
      default() {
        return undefined;
      }
    },
    // 每页数据量
    pageSize: {
      type: Number,
      default() {
        return 20
      }
    },

    tableStyle: {
      type: Number,
      default() {
        return 0
      }
    },

    empty_text: String,

    resetNoRequest: {
      type: Boolean,
      default() {
        return false
      }
    },

    isDownload: {
      type: Boolean,
      default() {
        return false
      }
    },

    downloadDisabled: {
      type: Boolean,
      default() {
        return false
      }
    },

    downloadText: {
      type: String,
      default() {
        return '导出数据'
      }
    },

    isCheckInputBox: {
      type: Boolean,
      default() {
        return true
      }
    },

    addText: {
      type: String,
      default() {
        return '新增'
      }
    },

    clearSort: {
      type: Boolean,
      default() {
        return false
      }
    },

    uploadFile: {
      type: Boolean,
      default() {
        return false
      }
    },

    isDisabled: {
      type: Object,
      default() {
        return {}
      }
    },

    isHide: {
      type: Object,
      default() {
        return {}
      }
    }

  },
  data() {
    return {
      flex:false,
      search: {},
      operationButtons: ['add', 'exportData', 'exportAll', 'changeAdmissionsTeacher', 'batchApprove', 'batchImport', 'initiateAppointment', 'supplement', 'qrCode', 'batchStateImport', 'leaveJobs', 'autoAllocate', 'startAllocate', 'exportList', 'oneClickThrough'],
      isExports: ['export', 'exportAll', 'exportData', 'importGrades', 'batchImport']//批量导入,导入成绩数据,导出,导出全部,导出数据
    }
  },
  watch: {
    search: {
      deep: true,
      handler(val) {
        this.$emit('onChange', val)
      }
    },

    clearSort: {
      deep: true,
      handler(val) {
        if (val) {
          this.$refs.table.clearSort()
        }
      }
    }
  },
  components: {
    TSearchBlock,
    TableTemplate,
    TableTemplateTop,
    't-button': Button
  },
  methods: {
    // 计算列的宽度(当有宽度属性时)
    computedColWidth(val){
      if (!val)return undefined;
      if (typeof val === "string"){
        val = val.match(/\d+/)[0].toNumber();
      }
      return this.$tools.calcRem(undefined, undefined, false) * val;
    },
    getButtonType(type) {
      if(['leaveJobs'].includes(type)) return 'warning'
      if(['startAllocate'].includes(type)) return 'success'
      return 'primary'
    },
    clearSelection() {
      this.$refs.table.clearSelection();
    },
    hasPermissions(type) {
      const item = this.buttonPermissions.find(item => item.type === type)
      return item ? true : false
    },
    isShowRenderButton(render, row) {
      if(render) {
        const res = render(row)
        if(res instanceof Array) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    showRenderValue(val) {
      if(val === 0) return val
      if(!val) return '-'
      return val
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.$emit('onChangePage', e)
    },
    /**
     * @description 表格的操作
     * @param row  当前行的数据
     * @param handle 操作按钮的文字
     * @param index 操作按钮的下标
     */
    onHandle(row, handle, index) {
      this.$emit('onHandle', row, handle, index)
    },
    onSwitch(row, index) {
      this.$emit('onSwitch', row, index)
    },
    cellStyle(row) {
      // type:  index , selection , expand
      if (row.column.type === 'selection') {
        return {
          paddingLeft: '9rem'
        }
      } else return {textIndent: '12rem', fontSize: '14rem'}
    },

    sortChange(column, prop, order) {
      this.$emit('onSortChange', column, prop, order)
    }
  },
  computed: {
    ...mapState(['buttonPermissions']),
    isList(){
      let matched = this.$route.matched;
      if (matched.length < 5){
        return false;
      }else if (matched[4].path.slice(-4) === 'list'){
        return true;
      }
      return false;
    },
    // 属性计算
    tableTemplateProperty(){
      return (type=0) => {
        let props = this.$props;
        let events = this.$listeners;
        let curProps = {},curEvents = [];
        let targetProps = {};
        let targetEvents = {};
        // 列表模板
        if (type === 0){
          curEvents = tableEvents;
          curProps = tableProps;
        }else{
          curEvents = tableTopEvents;
          curProps = topProps;
        }
        curEvents.forEach(item=>{
          let e = events[item];
          if (e){
            targetEvents[item] = e
          }
        })
        Object.keys(curProps).forEach(item=>{
          targetProps[item] = props[item];
        })
        return {
          props:targetProps,
          events:targetEvents
        };
      }
    }
  }

}
</script>

<style lang='scss' scoped>
.t-table {
  margin-right: 40rem;
}

.t-list {
  padding: 24rem 0 0 24rem;

  .t-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 40rem;
  }

  .t-handle {
    margin-right: 15rem;
    text-indent: 0;
  }

  .t-handle:last-child {
    margin-right: 0;
  }

  .t-pagination {
    margin-top: 40rem;
  }

  ::v-deep .el-table th.el-table__cell {
    text-align: left;
    background-color: rgba(232, 237, 255, 0.39);
    font-size: 14rem;
    padding-left: 12rem;
    font-weight: bold;
    color: #333333;
  }
}
</style>
